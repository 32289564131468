import { Injectable } from '@angular/core';

import {
  ALL_TIMESLOTS,
  Appointment,
  Event,
  Journey,
  Task,
  TaskMap,
  Timeslot,
  timeslotRelation,
  timeslotText,
} from '@nai-libs/data-access';
import { TaskUtilsService } from './task-utils.service';

@Injectable({ providedIn: 'root' })
export class TimeslotService {
  constructor(public taskUtils: TaskUtilsService) {}
  /**
   * Returns timeslot object given the timeslot in text format.
   *
   * @param { timeslotText } timeslotText
   * @returns empty timeslot object
   *
   */
  getTimeslot(timeslotText: timeslotText): Timeslot {
    type ObjectKey = keyof typeof timeslotRelation;
    const timeslotKey = timeslotText as ObjectKey;
    return timeslotRelation[timeslotKey] as Timeslot;
  }

  /**
   * Returns timeslot index given the timeslot in text format.
   *
   * @param { timeslotText } timeslotText
   * @returns index of the timeslot in the timeslot constant
   *
   */
  getTimeslotIndex(timeslotText: timeslotText): number {
    return Object.keys(timeslotRelation).findIndex(
      (key) => key === timeslotText
    );
  }

  /**
   *  Given a taskmap, it returns tasks ordered in timeslots.
   *
   * @param { taskMap } taskMap
   * @returns tasks ordered in timeslots
   *
   */
  taskMapToTimeslots(taskMap: TaskMap): Timeslot[] {
    const tasksInTimeslots = this.newTimeslots();

    taskMap?.['momentos-dia'].forEach((taskMoment) => {
      taskMoment.tareas.forEach((task) => {
        const index = this.getTimeslotIndex(taskMoment.momento);
        if (index > -1) {
          tasksInTimeslots[index].events.push(task);
        }
      });
    });
    return tasksInTimeslots;
  }

  taskMapToTasks(taskMap: TaskMap): Task[] {
    let tasks: Task[] = [];
    taskMap?.['momentos-dia'].forEach((taskMoment) => {
      tasks = tasks.concat(taskMoment.tareas);
    });
    return tasks;
  }

  appointmentsToTimeslots(
    appointments: Appointment[],
    selectedDay: Date
  ): Timeslot[] {
    const appointmentsBySlot = this.newTimeslots();
    if (appointments && appointments.length > 0) {
      appointmentsBySlot.forEach((timeslot, index) => {
        appointments.forEach((appointment) => {
          const timeslotStart = new Date(selectedDay);
          timeslotStart.setHours(parseInt(timeslot.start), 0, 0);
          const timeslotEnd = new Date(selectedDay);
          timeslotEnd.setHours(parseInt(timeslot.end), 0, 0);
          if (
            new Date(appointment.start) >= timeslotStart &&
            new Date(appointment.start) < timeslotEnd
          ) {
            appointmentsBySlot[index].events.push(appointment);
          }
        });
      });
    }
    return appointmentsBySlot;
  }

  mergeTimeslots(timeslots1: Timeslot[], timeslots2: Timeslot[]): Timeslot[] {
    const newTimeslots = [...timeslots1] as Timeslot[];
    for (let i = 0; i < timeslots2.length; i++) {
      timeslots2[i].events.forEach((timeslots2Event) => {
        newTimeslots[i].events.push(timeslots2Event);
      });
    }
    return newTimeslots;
  }

  isTask(event: Event): event is Task {
    return (event as Task).actividades !== undefined;
  }

  isAppointment(event: Event): event is Appointment {
    return (event as Appointment)['intervention-eid'] !== undefined;
  }

  isJourney(event: Event): event is Journey {
    return (event as Journey).isJourney;
  }

  hourBelongsToTimeslot(hour: string, timeslot: Timeslot): boolean {
    if (timeslot.start === '00' && timeslot.end === '00') {
      return false;
    }
    const today = new Date();

    const hourDate = new Date(today);
    hourDate.setHours(parseInt(hour.split(':')[0]));
    hourDate.setMinutes(parseInt(hour.split(':')[1]));

    const timeslotStart = new Date(today);
    timeslotStart.setHours(parseInt(timeslot.start));

    const timeslotEnd = new Date(today);
    timeslotEnd.setHours(parseInt(timeslot.end));
    if (
      timeslotStart.getTime() < hourDate.getTime() &&
      hourDate.getTime() <= timeslotEnd.getTime()
    ) {
      return true;
    }
    return false;
  }

  newTimeslots(): Timeslot[] {
    return JSON.parse(JSON.stringify(ALL_TIMESLOTS)) as Timeslot[];
  }

  countEvents(timeslots: Timeslot[]): number {
    return timeslots.reduce(
      (accumulator, current) => accumulator + current.events.length,
      0
    );
  }

  filterTasks(events: Event[]): Task[] {
    return events.filter((event) => this.isTask(event)) as Task[];
  }
  filterAppointments(events: Event[]): Appointment[] {
    return events.filter((event) => this.isAppointment(event)) as Appointment[];
  }

  getEvents(timeslots: Timeslot[]): Event[] {
    let events: Event[] = [];
    timeslots.forEach((timeslot) => {
      events = events.concat(timeslot.events);
    });
    return events;
  }

  countUncompletedEvents(timeslots: Timeslot[]) {
    const events = this.getEvents(timeslots);
    const tasks = this.filterTasks(events);
    const completedTasks = this.taskUtils.filterCompletedTasks(tasks);
    return events.length - completedTasks.length;
  }
}
