import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AuthInterceptorService,
  UserDataAccessModule,
} from '@nai-libs/user/data-access/src';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';

import { metaReducers, ROOT_REDUCERS } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { registerLocaleData } from '@angular/common';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AppointmentDataAccessModule } from '@nai-libs/appointment/data-access/src';
import { ArticleDataAccessModule } from '@nai-libs/article/data-access/src';
import { ClockInDataAccessModule } from '@nai-libs/clock-in/data-access/src';
import { ConsultationDataAccessModule } from '@nai-libs/consultation/data-access/src';
import { DgiDataAccessModule } from '@nai-libs/dgi/data-access/src';
import { DocumentDataAccessModule } from '@nai-libs/document/data-access/src';
import { HomeAccessibilityDataAccessModule } from '@nai-libs/home-accessibility/data-access/src';
import { NotificationDataAccessModule } from '@nai-libs/notification/data-access/src';
import { ProfessionalDataAccessModule } from '@nai-libs/professional/data-access/src';
import { PushNotificationsDataAccessModule } from '@nai-libs/push-notifications/data-access/src';
import { ScheduleDataAccessModule } from '@nai-libs/schedule/data-access/src';
import {
  CustomLocaleId,
  CustomMatDateAdapter,
  CustomTranslateLoader,
} from '@nai-libs/shared/utility/src';
import { TaskDataAccessModule } from '@nai-libs/task/data-access/src';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

export function loadTranslate(translateService: TranslateService) {
  return () => {
    translateService.use('macrosad-carer-es');
  };
}

registerLocaleData(localeEs, 'es');

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: ['DD/MM/YYYY'],
  },
  display: {
    dateInput: ['DD/MM/YYYY'],
    // monthYearLabel: { year: 'numeric' },
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    UserDataAccessModule,
    DgiDataAccessModule,
    AppointmentDataAccessModule,
    TaskDataAccessModule,
    ScheduleDataAccessModule,
    DocumentDataAccessModule,
    ArticleDataAccessModule,
    ProfessionalDataAccessModule,
    ConsultationDataAccessModule,
    HomeAccessibilityDataAccessModule,
    PushNotificationsDataAccessModule,
    NotificationDataAccessModule,
    ClockInDataAccessModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: LOCALE_ID,
      useClass: CustomLocaleId,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: APP_CONFIG, useValue: environment },
    ScreenOrientation,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService],
      useFactory: loadTranslate,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: CustomMatDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
