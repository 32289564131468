import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CLOCK_IN_FEATURE_KEY, ClockInState } from './clock-in.reducer';

export const clockInFetureSelector =
  createFeatureSelector<ClockInState>(CLOCK_IN_FEATURE_KEY);

export const selectScannerLastNFC = createSelector(
  clockInFetureSelector,
  (state) => state.scannerLastNFC
);
export const selectActiveIntervention = createSelector(
  clockInFetureSelector,
  (state) => state.activeIntervention
);
export const pending = createSelector(
  clockInFetureSelector,
  (state) => state.pending
);
