/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertService } from '@nai-libs/alert/features/alert-feature/src';
import { ClockInSuccessDialogComponent } from '@nai-libs/clock-in/features/clock-in-feature/src/lib/standalone/clock-in-success-dialog/clock-in-success.dialog';
import { ClockInPayload, Intervention } from '@nai-libs/shared/data-access/src';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ClockInActions, ClockInSelectors } from '../..';
import { ClockInService } from './clock-in.service';

@Injectable()
export class ClockInEffects {
  constructor(
    private actions$: Actions,
    private clockInService: ClockInService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private store: Store
  ) {}

  loadActiveIntervention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClockInActions.loadActiveIntervention),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([, user, serviceReceiver]) => {
        if (!user || !serviceReceiver) {
          return of(UserActions.logout());
        }
        if (serviceReceiver.intervention) {
          const intervention = {
            'aad-eid': user['logged-user'].id.toString(),
            hora_fin: serviceReceiver.intervention['end-date'].instant,
            hora_inicio: serviceReceiver.intervention['start-date'].instant,
          } as Intervention;
          return this.clockInService
            .fetchInterventionStatus(
              serviceReceiver['client-phone'],
              intervention
            )
            .pipe(
              map((status) => {
                return ClockInActions.loadActiveInterventionSuccess({
                  intervention,
                  status,
                });
              }),
              catchError(() =>
                of(ClockInActions.loadActiveInterventionFailure())
              )
            );
        }
        return [ClockInActions.loadActiveInterventionSuccess({})];
      })
    )
  );

  sendInterventionClockIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClockInActions.sendInterventionClockIn),
      withLatestFrom(
        this.store.select(ClockInSelectors.selectActiveIntervention),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ NFC_id }, intervention, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        const payload = {
          ...intervention,
          NFC_id,
          'client-phone': serviceReceiver['client-phone'],
        } as ClockInPayload;
        return this.clockInService.clockIn(payload).pipe(
          map((res) => {
            return res['success?']
              ? ClockInActions.sendInterventionClockInSuccess()
              : ClockInActions.sendInterventionClockInFailure();
          }),
          catchError(() => of(ClockInActions.sendInterventionClockInFailure()))
        );
      })
    )
  );

  sendInterventionClockInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClockInActions.sendInterventionClockInSuccess),
        tap(async () => {
          const modal = await this.modalController.create({
            component: ClockInSuccessDialogComponent,
            componentProps: {
              status: 'in',
            },
          });
          await modal.present();
          this.store.dispatch(ClockInActions.loadActiveIntervention());
        })
      ),
    { dispatch: false }
  );

  sendInterventionClockInFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClockInActions.sendInterventionClockInFailure),
        tap(() => {
          this.alertService.showAlert(
            'error',
            this.translateService.instant('USER.CLOCK_IN.ERROR'),
            10000,
            'top'
          );
          this.store.dispatch(ClockInActions.loadActiveIntervention());
        })
      ),
    { dispatch: false }
  );

  sendInterventionClockOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClockInActions.sendInterventionClockOut),
      withLatestFrom(
        this.store.select(ClockInSelectors.selectActiveIntervention),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ NFC_id }, intervention, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        const payload = {
          ...intervention,
          NFC_id,
          'client-phone': serviceReceiver['client-phone'],
        } as ClockInPayload;
        return this.clockInService.clockOut(payload).pipe(
          map((res) => {
            return res['success?']
              ? ClockInActions.sendInterventionClockOutSuccess()
              : ClockInActions.sendInterventionClockOutFailure();
          }),
          catchError(() => of(ClockInActions.sendInterventionClockOutFailure()))
        );
      })
    )
  );

  sendInterventionClockOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClockInActions.sendInterventionClockOutSuccess),
        tap(async () => {
          const modal = await this.modalController.create({
            component: ClockInSuccessDialogComponent,
            componentProps: {
              status: 'out',
            },
          });
          await modal.present();
          this.store.dispatch(ClockInActions.loadActiveIntervention());
        })
      ),
    { dispatch: false }
  );

  sendInterventionClockOutFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClockInActions.sendInterventionClockOutFailure),
        tap(() => {
          this.alertService.showAlert(
            'error',
            this.translateService.instant('USER.CLOCK_IN.ERROR'),
            10000,
            'top'
          );
          this.store.dispatch(ClockInActions.loadActiveIntervention());
        })
      ),
    { dispatch: false }
  );
}
