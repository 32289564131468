import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nai-libs/shared/utility/src';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'selector-familiar',
    loadChildren: () =>
      import(
        '@nai-libs/user/features/service-receiver-detailed-selector-feature'
      ).then((m) => m.ServiceReceiverDetailedSelectorFeatureModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/login',
    loadChildren: () =>
      import('@nai-libs/user/features/login-feature').then(
        (m) => m.LoginFeatureModule
      ),
  },
  {
    path: 'auth/recuperar-contrasena',
    loadChildren: () =>
      import('@nai-libs/user/features/password-recovery-feature').then(
        (m) => m.PasswordRecoveryFeatureModule
      ),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('@nai-libs/user/features/legal-feature').then(
        (m) => m.LegalFeatureModule
      ),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('@nai-libs/user/features/profile-feature').then(
        (m) => m.ProfileFeatureModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'notificaciones',
    loadChildren: () =>
      import('@nai-libs/notification/features/notification-feature').then(
        (m) => m.NotificationFeatureModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'estadisticas',
    loadChildren: () =>
      import('@nai-libs/task/features/task-statistics-feature/src').then(
        (m) => m.TaskStatisticsFeatureModule
      ),
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
